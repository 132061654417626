import $ from "jquery";
import { Modal } from "bootstrap";
import auditList from "@/view/pages/audit/deail/auditList.vue";
import auditRecord from "@/view/pages/audit/deail/auditRecord.vue";
import fileInput from "@/view/pages/common/fileInput.vue";
import comment from "@/view/pages/common/comment.vue";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
export default {
    components: {
        auditList,
        auditRecord,
        fileInput,
        comment
    },
    computed: {
        user: {
            get() {
                return this.$store.getters.currentUser;
            }
        },
        oaEmpList: {
            get() {
                return this.$store.state.systemData.oaEmpList;
            }
        },
        comList: {
            get() {
                return this.$store.state.systemData.comList;
            }
        },
        companyList: {
            get() {
                return this.$store.state.systemData.companyList;
            }
        },
        positionList: {
            get() {
                return this.$store.state.systemData.positionList;
            }
        },
        erpCompanyList: {
            get() {
                return this.$store.state.auditStore.erpCompanyList;
            }
        },
        supplychainComList: {
            get() {
                return this.$store.state.auditStore.supplychainComList;
            }
        },
        expenseTypeList: {
            get() {
                return this.$store.state.auditStore.expenseTypeList;
            }
        },
        customerList: {
            get() {
                return this.$store.state.auditStore.customerList;
            }
        },
        saleCompanyList: {
            get() {
                return this.$store.state.auditStore.saleCompanyList;
            }
        }
    },
    watch: {
        erpCompanyList: function() {
            if ($("#erpCompanyId").is(":visible")) {
                this.initSelectFour(
                    "erpCompanyId",
                    this.erpCompanyId,
                    this.erpCompanyList,
                    "bindErpId",
                    "name"
                );
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                const _this = this;
                // 递归 等待dom渲染完毕
                setTimeout(function() {
                    _this.initSelectFour(
                        "erpCompanyId",
                        _this.erpCompanyId,
                        _this.erpCompanyList,
                        "bindErpId",
                        "name"
                    );
                }, 500);
            }
        },
        supplychainComList: function() {
            if ($("#supplychain").is(":visible")) {
                this.initSelectFour(
                    "supplychain",
                    this.supplychainCompanyId,
                    this.supplychainComList,
                    "bindErpId",
                    "name"
                );
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                const _this = this;
                // 递归 等待dom渲染完毕
                setTimeout(function() {
                    _this.initSelectFour(
                        "supplychain",
                        _this.supplychainCompanyId,
                        _this.supplychainComList,
                        "bindErpId",
                        "name"
                    );
                }, 500);
            }
        },
        customerList: function() {
            if ($("#customer").is(":visible")) {
                this.initSelectFour(
                    "customer",
                    this.customerId,
                    this.customerList,
                    "recordId",
                    "concatValue"
                );
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                const _this = this;
                // 递归 等待dom渲染完毕
                setTimeout(function() {
                    _this.initSelectFour(
                        "customer",
                        _this.customerId,
                        _this.customerList,
                        "recordId",
                        "concatValue"
                    );
                }, 500);
            }
        },
        oaEmpList: function() {
            const _this = this;
            if (
                this.audit.auditType === "22001" ||
                this.audit.auditType === "22004" ||
                this.audit.auditType === "22015" ||
                this.audit.auditType === "22019"
            ) {
                if (this.oaEmpList && this.oaEmpList.length > 0) {
                    if ($("#passUser").is(":visible")) {
                        this.initSelect();
                    } else {
                        if (this.temp > 50) {
                            this.temp = 0;
                        }
                        this.temp++;
                        // 递归 等待dom渲染完毕
                        setTimeout(function() {
                            _this.initSelect();
                        }, 500);
                    }
                }
                if (this.oaEmpList && this.oaEmpList.length > 0) {
                    if ($("#notifierId").is(":visible")) {
                        this.initSelectThree();
                    } else {
                        if (this.temp > 50) {
                            this.temp = 0;
                        }
                        this.temp++;
                        // 递归 等待dom渲染完毕
                        setTimeout(function() {
                            _this.initSelectThree();
                        }, 500);
                    }
                }
            }
        },
        companyList: function() {
            if (this.audit && this.audit.stampCompany) {
                for (let i in this.companyList) {
                    if (this.audit.stampCompany === this.companyList[i].recordId) {
                        this.departList = this.companyList[i].departList;
                    }
                }
            }
        },
        comList: function() {
            if ($("#company").is(":visible")) {
                this.initDataCompany();
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                // 递归 等待dom渲染完毕
                var _this = this;
                setTimeout(function() {
                    _this.initDataCompany();
                }, 500);
            }
            // 采购申请、报销申请、出差申请、付款申请、借款申请
            if (
                this.audit.auditType === "22002" ||
                this.audit.auditType === "22003" ||
                this.audit.auditType === "22005" ||
                this.audit.auditType === "22013" ||
                this.audit.auditType === "22014" ||
                this.audit.auditType === "22021" ||
                this.audit.auditType === "22022"
            ) {
                if (this.audit.auditType === "22022") {
                    this.sourceComId = 5; // 默认为科易博
                }
                if (this.comList && this.comList.length > 1 && !this.editStatus) {
                    if (
                        !this.auditGroupDetailList ||
                        this.auditGroupDetailList.length === 0
                    ) {
                        this.auditGroupDetailList = [];
                        for (let com of this.comList) {
                            let auditGroupDetail = { depart: {} };
                            auditGroupDetail.depart.recordId = com.oaCompanyId;
                            auditGroupDetail.depart.departmentName = com.oaCompanyName;
                            this.auditGroupDetailList.push(auditGroupDetail);
                        }
                    } else if (
                        this.auditGroupDetailList &&
                        this.auditGroupDetailList.length > 0
                    ) {
                        // 如果分摊的公司不等于自己的所有公司，处理分摊的公司等于自己的所有公司
                        if (this.auditGroupDetailList.length != this.comList.length) {
                            for (let obj1 of this.comList) {
                                for (let obj2 of this.auditGroupDetailList) {
                                    if (
                                        obj2.depart &&
                                        obj1.oaCompanyId === obj2.depart.recordId
                                    ) {
                                        obj1.bindFlag = true;
                                        break;
                                    }
                                }
                            }
                            for (let obj of this.comList) {
                                if (!obj.bindFlag) {
                                    let auditGroupDetail = {
                                        depart: {},
                                        quantity: "",
                                        amount: "",
                                        percentage: ""
                                    };
                                    auditGroupDetail.depart.recordId = obj.oaCompanyId;
                                    auditGroupDetail.depart.departmentName = obj.oaCompanyName;
                                    this.auditGroupDetailList.push(auditGroupDetail);
                                }
                            }
                        }
                    }
                }
            }
        },
        expenseTypeList: function() {
            if (!this.expenseTypeList || this.expenseTypeList.length === 0) {
                return;
            }
            if (!this.expenseTypeId || this.expenseTypeId === "") {
                if (this.audit.expenseTypeId) {
                    this.expenseTypeId = this.audit.expenseTypeId;
                } else {
                    this.expenseTypeId = this.expenseTypeList[0].recordId;
                }
            }
            if ($("#expenseType").is(":visible")) {
                this.initSelectTwo("expenseType", this.expenseTypeId);
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                // 递归 等待dom渲染完毕
                var _this = this;
                setTimeout(function() {
                    _this.initSelectTwo("expenseType", _this.expenseTypeId);
                }, 500);
            }
        },
        saleCompanyList:function ()
        {
            this.handSaleCompanyList();
            this.getSaleCustomerList();
        }
    },
    mounted() {
        this.allAuditT = new Modal(this.$refs.allAudit);
        this.addAuditT = new Modal(this.$refs.addAudit);
        this.assentT = new Modal(this.$refs.assentAudit);
        this.rejectT = new Modal(this.$refs.rejectAudit);
        this.reimbursementWindowT = new Modal(this.$refs.reimbursementWindow);
        this.delAuditT = new Modal(this.$refs.delAudit);
    },
    data() {
        return {
            temp: 0,
            audit: {},
            passUserId: [],
            companyId: "",
            expenseList: [],
            restList: [],
            editStatus: true,
            auditRecord: {},
            content: "无意见",
            fileList: [],
            recordId: null,
            departList: [],
            auditGroupDetailList: [],
            auditGroupDetailFlag: true,
            expenseTypeId: "",
            sourceComId: null,
            recordIds: "",
            supplychainList: [],
            supplychainCompanyId: "",
            erpCompanyId: "",
            customerId: "",
            validityDate: "",
            allAuditT: "",
            addAuditT: "",
            assentT: "",
            rejectT: "",
            reimbursementWindowT: "",
            allAuditList: [
                {recordId: 22001, type: "22001", name: "请假申请单", remark: "暂无审批表描述", style: "alert-secondary", icon: "fa fa-sign-in"},
                {recordId: 22002, type: "22002", name: "采购申请单", remark: "暂无审批表描述", style: "alert-warning", icon: "fa fa-plane"},
                {recordId: 22003, type: "22003", name: "报销申请单", remark: "暂无审批表描述", style: "alert-success", icon: "fa fa-shopping-cart"},
                {recordId: 22004, type: "22004", name: "离职申请单", remark: "暂无审批表描述", style: "alert-danger", icon: "fa fa-sign-out"},
                {recordId: 22005, type: "22005", name: "出差申请单", remark: "暂无审批表描述", style: "alert-secondary", icon: "fa fa-external-link"},
                {recordId: 22007, type: "22007", name: "人员增补申请单", remark: "暂无审批表描述", style: "alert-secondary", icon: "fa fa-plus"},
                {recordId: 22008, type: "22008", name: "工作调动申请单", remark: "暂无审批表描述", style: "alert-info", icon: "fa fa-exchange"},
                {recordId: 22009, type: "22009", name: "工作请示申请单", remark: "暂无审批表描述", style: "alert-warning", icon: "fa fa-share"},
                {recordId: 22010, type: "22010", name: "转正申请单", remark: "暂无审批表描述", style: "alert-info", icon: "fa fa-heart"},
                {recordId: 22011, type: "22011", name: "调休申请单", remark: "暂无审批表描述", style: "alert-success", icon: "fa fa-gift"},
                {recordId: 22012, type: "22012", name: "补卡申请单", remark: "暂无审批表描述", style: "alert-warning", icon: "fa fa-calendar"},
                {recordId: 22013, type: "22013", name: "付款申请单", remark: "暂无审批表描述", style: "alert-danger", icon: "fa fa-random"},
                {recordId: 22014, type: "22014", name: "借款申请单", remark: "暂无审批表描述", style: "alert-danger", icon: "fa fa-refresh"},
                {recordId: 22015, type: "22015", name: "用印申请单", remark: "暂无审批表描述", style: "alert-info", icon: "fa fa-check"},
                {recordId: 22016, type: "22016", name: "外出申请单", remark: "暂无审批表描述", style: "alert-secondary", icon: "fa fa-road"},
                {recordId: 22017, type: "22017", name: "公司报表审批", remark: "暂无审批表描述", style: "alert-secondary", icon: "fa fa-share"},
                {recordId: 22018, type: "22018", name: "收付款计划审批", remark: "暂无审批表描述", style: "alert-success", icon: "fa fa-share"},
                {recordId: 22019, type: "22019", name: "财务报表审批", remark: "暂无审批表描述", style: "alert-primary", icon: "fa fa-share"},
                {recordId: 22020, type: "22020", name: "工资核算审批", remark: "暂无审批表描述", style: "alert-primary", icon: "fa fa-share"},
                {recordId: 22021, type: "22021", name: "集团内部资金调拨审批", remark: "暂无审批表描述", style: "alert-success", icon: "fa fa-share"},
                {recordId: 22022, type: "22022", name: "业务费付款申请单", remark: "暂无审批表描述", style: "alert-danger", icon: "fa fa-random"},
                {recordId: 22023, type: "22023", name: "供应链授权申请单", remark: "暂无审批表描述", style: "alert-primary", icon: "fa fa-random"},
                {recordId: 22026, type: "22026", name: "客户汇款审批", remark: "暂无审批表描述", style: "alert-primary", icon: "fa fa-random"},
                {recordId: 22024, type: "22024", name: "奖金分配申请单", remark: "暂无审批表描述", style: "alert-primary", icon: "fa fa-random"},
            ],
            auditDeail: {},
            saleCustomerList:[],
            saleCompanyId:"",
            saleCustomId:"",
            notifier: [],
            allAuditListT:[],
            configList:[],
        };
    },
    methods: {
        reflush: function () {
            $('#passUser').selectpicker('destroy');
        },
        setFileList: function (data) {
            this.fileList = data;
        },
        initSelect: function() {
            $("#passUser").empty();
            let option = "";
            option += "<option value=''>" + "请选择" + "</option>";
            this.oaEmpList.forEach(el => {
                option +=
                    "<option value='" + el.recordId + "'>" + el.name + "</option>";
            });
            $("#passUser").append(option);
            $("#passUser").selectpicker("val", this.passUserId);
            $("#passUser").selectpicker("render");
            $("#passUser").selectpicker("refresh");
            $("#passUser").selectpicker();
        },
        initSelectTwo: function(id, typeId) {
            $("#" + id).empty();
            let option = "";
            option += "<option value=''>" + "请选择" + "</option>";
            this.expenseTypeList.forEach(el => {
                option +=
                    "<option value='" +
                    el.recordId +
                    "'>" +
                    el.no +
                    " " +
                    el.name +
                    "</option>";
            });
            $("#" + id).append(option);
            $("#" + id).selectpicker("val", typeId);
            $("#" + id).selectpicker("render");
            $("#" + id).selectpicker("refresh");
            $("#" + id).selectpicker();
            if (!typeId && this.expenseTypeList[0]) {
                typeId = this.expenseTypeList[0].recordId;
            }
            $("#" + id).selectpicker("val", typeId);
        },
        initSelectThree: function() {
            $("#notifierId").empty();
            let option = "";
            option += "<option value=''>" + "请选择" + "</option>";
            this.oaEmpList.forEach(el => {
                option +=
                    "<option value='" + el.recordId + "'>" + el.name + "</option>";
            });
            $("#notifierId").append(option);
            $("#notifierId").selectpicker("val", this.notifier);
            $("#notifierId").selectpicker("render");
            $("#notifierId").selectpicker("refresh");
            $("#notifierId").selectpicker();
        },
        initDataCompany: function() {
            $("#company").empty();
            let option = "";
            option += "<option value=''>" + "请选择" + "</option>";
            this.comList.forEach(el => {
                option +=
                    "<option value='" + el.allId + "'>" + el.departmentName + "</option>";
            });
            $("#company").append(option);
            $("#company").selectpicker("val", this.companyId);
            $("#company").selectpicker("render");
            $("#company").selectpicker("refresh");
            $("#company").selectpicker();
        },
        initDate: function(id, startDates) {
            if ($("#" + id + "").is(":visible")) {
                $("#" + id + "").daterangepicker(
                    {
                        singleDatePicker: true,
                        showDropdowns: true,
                        timePicker: true,
                        timePicker24Hour: true,
                        startDate: startDates, // 设置开始日期
                        opens: "center",
                        drops: "down",
                        locale: {
                            format: "YYYY-MM-DD HH:mm:ss",
                            separator: " - ",
                            applyLabel: "确定",
                            cancelLabel: "取消",
                            fromLabel: "From",
                            toLabel: "到",
                            customRangeLabel: "Custom",
                            weekLabel: "W",
                            daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
                            monthNames: [
                                "一月",
                                "二月",
                                "三月",
                                "四月",
                                "五月",
                                "六月",
                                "七月",
                                "八月",
                                "九月",
                                "十月",
                                "十一月",
                                "十二月"
                            ],
                            firstDay: 1
                        }
                    },
                    function(start, end, label) {}
                );
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                // 递归 等待dom渲染完毕
                const _this = this;
                setTimeout(function() {
                    _this.initDate(id, startDates);
                }, 500);
            }
        },
        initDateTwo: function(id, startDates) {
            if ($("#" + id + "").is(":visible")) {
                $("#" + id + "").daterangepicker(
                    {
                        singleDatePicker: true,
                        showDropdowns: true,
                        timePicker: false,
                        timePicker24Hour: false,
                        minDate: new Date(),
                        startDate: startDates, // 设置开始日期
                        opens: "center",
                        drops: "down",
                        locale: {
                            format: "YYYY-MM-DD",
                            separator: " - ",
                            applyLabel: "确定",
                            cancelLabel: "取消",
                            fromLabel: "From",
                            toLabel: "到",
                            customRangeLabel: "Custom",
                            weekLabel: "W",
                            daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
                            monthNames: [
                                "一月",
                                "二月",
                                "三月",
                                "四月",
                                "五月",
                                "六月",
                                "七月",
                                "八月",
                                "九月",
                                "十月",
                                "十一月",
                                "十二月"
                            ],
                            firstDay: 1
                        }
                    },
                    function(start, end, label) {}
                );
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                // 递归 等待dom渲染完毕
                const _this = this;
                setTimeout(function() {
                    _this.initDateTwo(id, startDates);
                }, 500);
            }
        },
        allAudit: function() {
            this.allAuditT.show();
        },
        openAudit: function(item, companyId) {
            this.expenseList = [];
            if (item.expenseList) {
                this.expenseList = item.expenseList;
            }
            this.companyId = companyId;
            // 弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            let type = "";
            this.audit = {};
            this.fileList = [];
            this.auditGroupDetailList = [];
            this.supplychainList = [];
            this.auditDeail = {};
            $("#file").val("");
            if (item && item.no) {
                for (let i=0;i<this.allAuditList.length;i++){
                    if (this.allAuditList[i].type == item.auditType){
                        this.auditDeail = this.allAuditList[i];
                        break;
                    }
                }
                if (
                    item.empId === this.user.employeeId &&
                    (item.applicationsType === "1" ||
                        (item.applicationsType === "3" &&
                            item.applicationsResult === "reject"))
                ) {
                    this.editStatus = false;
                } else {
                    this.editStatus = true;
                }
                this.audit = item;
                type = this.audit.auditType;
                if (item.attachList) {
                    this.fileList = item.attachList;
                }
                if (item.passUser) {
                    this.passUserId.push(item.passUser);
                }
                if (item.auditGroupDetailList) {
                    this.auditGroupDetailList = item.auditGroupDetailList;
                }
                if (item.supplychainList) {
                    this.supplychainList = item.supplychainList;
                }
                if (item.notifier)
                {
                    this.notifier = item.notifier.split(",");
                    this.initSelectThree();
                }
                if (item.restList) {
                    this.restList = item.restList;
                    for (let i = 0; i < this.restList.length; i++) {
                        this.initDate("addStart" + i, this.restList[i].workStartDate);
                        this.initDate("addEnd" + i, this.restList[i].workEndDate);
                        this.initDate("restStart" + i, this.restList[i].restStartDate);
                        this.initDate("restEnd" + i, this.restList[i].restEndDate);
                    }
                }
                // 认证未审批或者记录都是自动审批
                item.draftFlag = "";
                if (item.recordList && item.recordList.length > 0) {
                    // 先获取提交人,并且提交人等于当前操作人
                    let draftFlag = false;
                    if (
                        item.recordList[0] &&
                        item.recordList[0].createdBy &&
                        item.recordList[0].createdBy.recordId &&
                        item.recordList[0].createdBy.recordId === this.user.employeeId
                    ) {
                        for (let i = 0; i < item.recordList.length; i++) {
                            if (
                                item.recordList[i] &&
                                item.recordList[i].lastUpdBy &&
                                item.recordList[i].lastUpdBy.recordId
                            ) {
                                if (
                                    item.recordList[i].lastUpdBy.recordId !== this.user.employeeId
                                ) {
                                    draftFlag = true;
                                    break;
                                }
                            }
                        }
                    } else {
                        draftFlag = true;
                    }
                    if (!draftFlag) {
                        item.draftFlag = "1";
                    }
                }
            } else {
                this.auditDeail = item;
                this.editStatus = false;
                this.audit.auditType = item.type;
                this.audit.empName = this.user.empName;
                type = item.type;
                if (type === "22004") {
                    this.audit.contractStartDate = this.user.contractStartDate;
                    this.audit.contractEndDate = this.user.contractEndDate;
                    this.audit.hiredDate = this.user.hiredDate;
                    this.audit.position = this.user.position;
                }
                if (type === "22010") {
                    this.audit.hiredDate = this.user.hiredDate;
                    this.audit.regularWorkerDate = this.user.regularWorkerDate;
                }
            }
            const emp = {};
            emp.recordId = this.user.employeeId;
            this.$store.dispatch("systemData/setComList", emp);
            if (type === "22001") {
                this.initDate("start", this.audit.startTime);
                this.initDate("end", this.audit.endTime);
                const depart = {};
                depart.recordId = this.user.departId;
                depart.workStatus = 1;
                this.$store.dispatch("systemData/loadOaEmpList", depart);
            } else if (type === "22004") {
                this.initDate("end", this.audit.endTime);
                const depart = {};
                depart.recordId = this.user.departId;
                depart.workStatus = 1;
                this.$store.dispatch("systemData/loadOaEmpList", depart);
            } else if (type === "22005") {
                this.initDate("start", this.audit.startTime);
                this.initDate("end", this.audit.endTime);
            } else if (type === "22007") {
                this.initDate("start", this.audit.recriotJoinDate);
            } else if (type === "22008") {
                this.initDate("start", this.audit.startTime);
                this.initDate("end", this.audit.endTime);
            } else if (type === "22009") {
                this.initDate("start", this.audit.askDate);
            } else if (type === "22010") {
                this.initDate("start", this.audit.contractStartDate);
                if (this.audit.contractEndDate) {
                    this.initDate("end", this.audit.contractEndDate);
                } else {
                    this.initDate("end");
                }
            } else if (type === "22012") {
                this.initDate("start", this.audit.startTime);
                this.initDate("end", this.audit.endTime);
            } else if (type === "22013") {
                this.initDate("start", this.audit.startTime);
            } else if (type === "22015") {
                this.initDate("start", this.audit.startTime);
                const depart = {};
                depart.recordId = this.user.departId;
                depart.workStatus = 1;
                this.$store.dispatch("systemData/setCompanyList", depart);
                this.$store.dispatch("systemData/loadOaEmpList", depart);
                // 印章复选框选中
                this.loadChildType();
            } else if (type === "22016") {
                this.initDate("start", this.audit.startTime);
                this.initDate("end", this.audit.endTime);
            } else if (type === "22003") {
                if (!this.audit.expenseTypeId || this.audit.expenseTypeId === "") {
                    this.expenseTypeId = "";
                }
                this.$store.dispatch("auditStore/getExpenseTypeList");
            } else if (type === "22021") {
                this.initDate("start", this.audit.startTime);
            } else if (type === "22022") {
                this.initDate("start", this.audit.startTime);
                if (!this.audit.payObject) {
                    this.audit.payObject = "深圳香澜文化传播有限公司";
                }
                if (!this.audit.auditResult) {
                    this.audit.auditResult =
                        "客户：" +
                        "\n" +
                        "回款：" +
                        "\n" +
                        "账号：" +
                        "\n" +
                        "开户名：" +
                        "\n" +
                        "开户行：";
                }
            } else if (type === "22023") {
                this.validityDate = new Date();
                if (this.supplychainList && this.supplychainList.length > 0) {
                    this.supplychainCompanyId = this.supplychainList[0].supplychainCompanyId;
                    this.erpCompanyId = this.supplychainList[0].companyId;
                    this.customerId = this.supplychainList[0].customerId;
                    this.validityDate = this.supplychainList[0].validityDateStr;
                } else {
                    this.supplychainCompanyId = "";
                    this.erpCompanyId = "";
                    this.customerId = "";
                }
                // 加载供应链授权申请数据
                this.initDateTwo("validityTime", this.validityDate);
                this.$store.dispatch("auditStore/getSupplychainApplyData");
                if (this.erpCompanyId) {
                    this.getCustomerList();
                }
            }else if (type === "22026"){
                if (item)
                {
                    this.saleCompanyId = item.saleCompanyId;
                    this.saleCustomId = item.saleCustomId;
                }
                this.$store.dispatch("auditStore/getSupplychainApplyData");
                this.$store.dispatch("auditStore/getSaleCompanyList");
            }else if(type === "22019")
            {
                const depart = {};
                depart.recordId = this.user.departId;
                depart.workStatus = 1;
                this.$store.dispatch("systemData/loadOaEmpList", depart);
            }
            this.addAuditT.show();
            // // 弹窗结束
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        },
        initNewDate() {
            const addStartId = "addStart" + (this.restList.length - 1);
            const addEndId = "addEnd" + (this.restList.length - 1);
            const restStartId = "restStart" + (this.restList.length - 1);
            const restEndId = "restEnd" + (this.restList.length - 1);
            this.initDate(addStartId);
            this.initDate(addEndId);
            this.initDate(restStartId);
            this.initDate(restEndId);
        },
        reduceRest: function(index) {
            if (this.restList && this.restList.length > 1) {
                this.restList.splice(index, 1);
            } else {
                alert("必须要有一个调休事项");
            }
        },
        addRest: function() {
            const rest = {};
            this.restList.push(rest);
            this.initNewDate();
        },
        saveAudit: function() {
            if (this.audit) {
                if (this.companyId) {
                    this.audit.oaDepartId = this.companyId;
                    this.audit.empId = this.user.employeeId;
                    this.audit.sourceComId = this.sourceComId;
                    this.audit.groupId = this.user.departId;
                } else {
                    alert("请选择架构");
                    return;
                }
                let start = "";
                let end = "";
                let totalAmount = 0;
                let qty = 0;
                if (
                    this.audit.auditType === "22001" ||
                    this.audit.auditType === "22016" ||
                    this.audit.auditType === "22012" ||
                    this.audit.auditType === "22013" ||
                    this.audit.auditType === "22015" ||
                    this.audit.auditType === "22008" ||
                    this.audit.auditType === "22005" ||
                    this.audit.auditType === "22021" ||
                    this.audit.auditType === "22022"
                ) {
                    start = $("#start").val();
                    this.audit.startTime = start;
                }
                if (this.audit.auditType === "22009") {
                    start = $("#start").val();
                    this.audit.askDate = start;
                }
                if (this.audit.auditType === "22010") {
                    start = $("#start").val();
                    this.audit.contractStartDate = start;
                    end = $("#end").val();
                    this.audit.contractEndDate = end;
                    if (!this.audit.tryPost) {
                        alert("请填写试用岗位！");
                        return;
                    }
                    if (!this.audit.formalPost) {
                        alert("请填写转正岗位！");
                        return;
                    }
                }
                if (
                    this.audit.auditType === "22001" ||
                    this.audit.auditType === "22004" ||
                    this.audit.auditType === "22016" ||
                    this.audit.auditType === "22012" ||
                    this.audit.auditType === "22008" ||
                    this.audit.auditType === "22005"
                ) {
                    end = $("#end").val();
                    this.audit.endTime = end;
                }
                if (this.audit.auditType === "22004") {
                    if (this.passUserId.length > 0) {
                        this.audit.passUser = this.passUserId[0];
                    } else {
                        alert("请选择交接人员");
                        return;
                    }
                    if (!this.audit.passResult) {
                        alert("请填写交接事项");
                        return;
                    }
                }
                if (this.audit.auditType === "22002") {
                    if (!this.audit.purchaseName) {
                        alert("请填写采购物品");
                        return;
                    }
                    if (!this.audit.purchaseNum) {
                        alert("请填写采购数量");
                        return;
                    }
                    if (isNaN(this.audit.purchaseNum) || this.audit.purchaseNum <= 0) {
                        alert("采购数量必须为大于0的数字");
                        this.$set(this.audit, "purchaseNum", null);
                        return;
                    }
                    if (!this.audit.purchasePrice) {
                        alert("请填写采购单价");
                        return;
                    }
                    if (
                        isNaN(this.audit.purchasePrice) ||
                        this.audit.purchasePrice <= 0
                    ) {
                        alert("采购单价必须为大于0的数字");
                        this.$set(this.audit, "purchasePrice", null);
                        return;
                    }
                    qty = this.audit.purchaseNum;
                }
                if (
                    this.audit.auditType === "22001" ||
                    this.audit.auditType === "22002" ||
                    this.audit.auditType === "22004" ||
                    this.audit.auditType === "22009" ||
                    this.audit.auditType === "22003" ||
                    this.audit.auditType === "22010" ||
                    this.audit.auditType === "22016" ||
                    this.audit.auditType === "22014" ||
                    this.audit.auditType === "22012" ||
                    this.audit.auditType === "22013" ||
                    this.audit.auditType === "22007" ||
                    this.audit.auditType === "22011" ||
                    this.audit.auditType === "22015" ||
                    this.audit.auditType === "22008" ||
                    this.audit.auditType === "22005" ||
                    this.audit.auditType === "22017" ||
                    this.audit.auditType === "22018" ||
                    this.audit.auditType === "22019" ||
                    this.audit.auditType === "22020" ||
                    this.audit.auditType === "22021" ||
                    this.audit.auditType === "22022" ||
                    this.audit.auditType === "22023"
                ) {
                    if (!this.audit.auditResult) {
                        alert("请填写原因");
                        return;
                    }
                }
                if (this.audit.auditType === "22003") {
                    this.audit.applicationsType = "2";
                    if (this.expenseList && this.expenseList.length > 0) {
                        for (let i = 0; i < this.expenseList.length; i++) {
                            if (
                                this.expenseList[i].expenseDesc &&
                                this.expenseList[i].expenseSum &&
                                this.expenseList[i].expenseNum
                            ) {
                                continue;
                            } else {
                                alert("报销明细的内容需要填写完整！");
                                return;
                            }
                        }
                    } else {
                        alert("请填报销明细！");
                        return;
                    }
                    this.audit.expenseList = this.expenseList;
                    this.audit.expenseTypeId = this.expenseTypeId;
                    totalAmount = this.audit.money;
                    if (!this.audit.reimbursementFor) {
                        this.$set(this.audit, "reimbursementFor", "提交");
                    }
                    this.reimbursementWindowT.show();
                    return;
                }
                if (
                    this.audit.auditType === "22001" ||
                    this.audit.auditType === "22004" ||
                    this.audit.auditType === "22015"
                ) {
                    if (this.passUserId.length > 0) {
                        this.audit.passUser = this.passUserId[0];
                    } else {
                        alert("请选择人员");
                        return;
                    }
                }
                if (
                    this.audit.auditType === "22001" ||
                    this.audit.auditType === "22009" ||
                    this.audit.auditType === "22014" ||
                    this.audit.auditType === "22013" ||
                    this.audit.auditType === "22021" ||
                    this.audit.auditType === "22022"
                ) {
                    if (!this.audit.childType) {
                        alert("请填写类型");
                        return;
                    }
                }
                if (
                    this.audit.auditType === "22001" ||
                    this.audit.auditType === "22016" ||
                    this.audit.auditType === "22012" ||
                    this.audit.auditType === "22011" ||
                    this.audit.auditType === "22005"
                ) {
                    if (!this.audit.days) {
                        alert("请填写天数");
                        return;
                    }
                    if (isNaN(this.audit.days) || this.audit.days <= 0) {
                        alert("天数必须为大于0的数字");
                        this.$set(this.audit, "days", null);
                        return;
                    }
                }
                if (
                    this.audit.auditType === "22001" ||
                    this.audit.auditType === "22016" ||
                    this.audit.auditType === "22012" ||
                    this.audit.auditType === "22005"
                ) {
                    if (end <= start) {
                        alert("结束时间要大于开始时间");
                        return;
                    }
                }
                if (this.audit.auditType === "22014") {
                    if (!this.audit.expensePrice) {
                        alert("请填写借款金额！");
                        return;
                    }
                    if (isNaN(this.audit.expensePrice) || this.audit.expensePrice <= 0) {
                        alert("借款金额必须为大于0的数字");
                        this.$set(this.audit, "expensePrice", null);
                        return;
                    }
                    totalAmount = this.audit.expensePrice;
                }
                if (
                    this.audit.auditType === "22013" ||
                    this.audit.auditType === "22021" ||
                    this.audit.auditType === "22022"
                ) {
                    if (!this.audit.expensePrice) {
                        alert("请填写付款金额！");
                        return;
                    }
                    if (isNaN(this.audit.expensePrice) || this.audit.expensePrice <= 0) {
                        alert("付款金额必须为大于0的数字");
                        this.$set(this.audit, "expensePrice", null);
                        return;
                    }
                    if (!this.audit.payMethod) {
                        alert("请填付款方式！");
                        return;
                    }
                    if (!this.audit.payObject) {
                        alert("请填写支付对象！");
                        return;
                    }
                    if (!this.audit.bank && this.audit.auditType !== "22022") {
                        alert("请填写开户行！");
                        return;
                    }
                    if (!this.audit.bankAccount && this.audit.auditType !== "22022") {
                        alert("请填写银行账号！");
                        return;
                    }
                    totalAmount = this.audit.expensePrice;
                }
                if (this.audit.auditType === "22007") {
                    start = $("#start").val();
                    this.audit.recriotJoinDate = start;
                    if (!this.audit.recruitPost) {
                        alert("请填写申请岗位！");
                        return;
                    }
                    if (!this.audit.recruitNum) {
                        alert("请填写人数！");
                        return;
                    }
                    if (isNaN(this.audit.recruitNum) || this.audit.recruitNum <= 0) {
                        alert("人数必须为大于0的数字");
                        this.$set(this.audit, "recruitNum", null);
                        return;
                    }
                    if (!/^\d+$/.test(this.audit.recruitNum)) {
                        alert("人数必须为整数");
                        this.$set(this.audit, "recruitNum", null);
                        return;
                    }
                }
                if (this.audit.auditType === "22011") {
                    if (this.restList && this.restList.length > 0) {
                        for (let i = 0; i < this.restList.length; i++) {
                            const addStartId = "#addStart" + i;
                            const addEndId = "#addEnd" + i;
                            const restStartId = "#restStart" + i;
                            const restEndId = "#restEnd" + i;
                            const workStartDate = $(addStartId).val();
                            const workEndDate = $(addEndId).val();
                            const restStartDate = $(restStartId).val();
                            const restEndDate = $(restEndId).val();
                            if (
                                workEndDate &&
                                workStartDate &&
                                restEndDate &&
                                restStartDate
                            ) {
                                if (!(workEndDate > workStartDate)) {
                                    alert("加班结束时间要大于加班开始时间！");
                                    return;
                                }
                                if (!(restEndDate > restStartDate)) {
                                    alert("调休结束时间要大于调休开始时间！");
                                    return;
                                }
                                this.restList[i].workEndDate = workEndDate;
                                this.restList[i].workStartDate = workStartDate;
                                this.restList[i].restEndDate = restEndDate;
                                this.restList[i].restStartDate = restStartDate;
                            } else {
                                alert("调休明细的内容需要填写完整！");
                                return;
                            }
                        }
                    } else {
                        alert("请填调休明细！");
                        return;
                    }
                    this.audit.restList = this.restList;
                }
                if (this.audit.auditType === "22015") {
                    this.audit.childType = "";
                    const obj = document.getElementsByName("seal");
                    for (let k in obj) {
                        if (obj[k].checked) {
                            if (this.audit.childType) {
                                this.audit.childType =
                                    this.audit.childType + "," + obj[k].value;
                            } else {
                                this.audit.childType = obj[k].value;
                            }
                        }
                    }
                    if (!this.audit.stampCompany) {
                        alert("请选择用印公司！");
                        return;
                    }
                    if (!this.audit.stampDept) {
                        alert("请选择用印部门！");
                        return;
                    }
                    if (!this.audit.stamp) {
                        alert("请选择需要印章！");
                        return;
                    }
                    if (!this.audit.childType) {
                        alert("请选择印章！");
                        return;
                    }
                    if (!this.audit.stampCount) {
                        alert("请填写印章次数！");
                        return;
                    }
                    if (isNaN(this.audit.stampCount) || this.audit.stampCount <= 0) {
                        alert("印章次数必须为大于0的数字");
                        this.$set(this.audit, "stampCount", null);
                        return;
                    }
                    if (!/^\d+$/.test(this.audit.stampCount)) {
                        alert("印章次数必须为整数");
                        this.$set(this.audit, "stampCount", null);
                        return;
                    }
                }
                if (this.audit.auditType === "22008") {
                    if (!this.audit.transferEducation) {
                        alert("请填写本人学历！");
                        return;
                    }
                    if (!this.audit.transferGraduateSchool) {
                        alert("请填写毕业院校！");
                        return;
                    }
                    if (!this.audit.transferDept) {
                        alert("请填写调入部门！");
                        return;
                    }
                    if (!this.audit.transferPost) {
                        alert("请填写岗位名称！");
                        return;
                    }
                }
                if (this.audit.auditType === "22005") {
                    if (!this.audit.travelAddress) {
                        alert("请填写出差地点！");
                        return;
                    }
                    if (!this.audit.expensePrice) {
                        alert("请填写预算费用！");
                        return;
                    }
                    if (isNaN(this.audit.expensePrice) || this.audit.expensePrice <= 0) {
                        alert("预算费用必须为大于0的数字");
                        this.$set(this.audit, "expensePrice", null);
                        return;
                    }
                    totalAmount = this.audit.expensePrice;
                }

                if(this.audit.auditType === '22026')
                {
                    if (!this.saleCompanyId)
                    {
                        alert("请选择销售公司！");
                        return;
                    }
                    if (!this.saleCustomId)
                    {
                        alert("请选择客户！");
                        return;
                    }
                    if (!this.audit.amountReceived)
                    {
                        alert("请输入汇款金额");
                        return;
                    }
                    if (!this.audit.remark)
                    {
                        alert("请填写备注内容");
                        return;
                    }
                }
                if(this.audit.auditType === '22019')
                {
                    if(this.notifier && this.notifier.length > 0)
                    {
                        let notifiIds = null;
                        for(let notifi of this.notifier)
                        {
                            if (notifiIds == null)
                            {
                                notifiIds = notifi;
                            }else{
                                notifiIds = notifiIds + "," + notifi;
                            }
                        }
                        this.audit.notifier = notifiIds;
                    }
                }
                this.audit.saleCompanyId = this.saleCompanyId;
                this.audit.saleCustomId = this.saleCustomId;
                if (this.audit.auditType === "22011") {
                    if (this.restList && this.restList.length > 0) {
                        for (let i = 0; i < this.restList.length; i++) {
                            const addStartId = "#addStart" + i;
                            const addEndId = "#addEnd" + i;
                            const restStartId = "#restStart" + i;
                            const restEndId = "#restEnd" + i;
                            const workStartDate = $(addStartId).val();
                            const workEndDate = $(addEndId).val();
                            const restStartDate = $(restStartId).val();
                            const restEndDate = $(restEndId).val();
                            if (
                                workEndDate &&
                                workStartDate &&
                                restEndDate &&
                                restStartDate
                            ) {
                                if (!(workEndDate > workStartDate)) {
                                    alert("加班结束时间要大于加班开始时间！");
                                    return;
                                }
                                if (!(restEndDate > restStartDate)) {
                                    alert("调休结束时间要大于调休开始时间！");
                                    return;
                                }
                                this.restList[i].workEndDate = workEndDate;
                                this.restList[i].workStartDate = workStartDate;
                                this.restList[i].restEndDate = restEndDate;
                                this.restList[i].restStartDate = restStartDate;
                            } else {
                                alert("调休明细的内容需要填写完整！");
                                return;
                            }
                        }
                    } else {
                        alert("请填调休明细！");
                        return;
                    }
                    this.audit.restList = this.restList;
                }

                if (this.auditGroupDetailList && this.auditGroupDetailList.length > 0) {
                    var total = 0;
                    var list = []; // 保存的分摊记录(金额大于0的)
                    for (let obj of this.auditGroupDetailList) {
                        list.push(obj);
                        if (this.audit.auditType === "22002") {
                            if (obj.quantity && Number(obj.quantity) > 0) {
                                total += Number(obj.quantity);
                            }
                        } else {
                            if (obj.amount && Number(obj.amount) > 0) {
                                total += Number(obj.amount);
                            }
                        }
                    }
                    if (this.audit.auditType !== "22002") {
                        total = Number(total.toFixed(4));
                    }
                    if (total === 0) {
                        // 不分摊
                    } else if (this.audit.auditType === "22002" && total != Number(qty)) {
                        alert("所有分摊的数量要等于总数量");
                        return;
                    } else if (
                        this.audit.auditType !== "22002" &&
                        total != Number(totalAmount)
                    ) {
                        alert("所有分摊的金额要等于总金额");
                        return;
                    } else {
                        this.audit.auditGroupDetailList = list;
                    }
                }
                if (this.audit.auditType === "22023") {
                    if (!this.erpCompanyId) {
                        alert("请选择授权公司！");
                        return;
                    }
                    if (!this.customerId) {
                        alert("请选择授权客户！");
                        return;
                    }
                    if (!this.supplychainCompanyId) {
                        alert("请选择供应链！");
                        return;
                    }
                    this.validityDate = $("#validityTime").val();
                    if (!this.validityDate) {
                        alert("请填写授权终止！");
                        return;
                    }
                    let supplychainList = [];
                    const supplychain = {};
                    supplychain.companyId = this.erpCompanyId;
                    supplychain.customerId = this.customerId;
                    supplychain.supplychainCompanyId = this.supplychainCompanyId;
                    supplychain.validityDate = this.validityDate;
                    supplychainList.push(supplychain);
                    this.audit.supplychainList = supplychainList;
                }
                if (this.audit.auditType !== "22003") {
                    // 弹窗开始
                    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                    // 通过form表单进行提交
                    const param = new FormData();
                    param.append("audit", JSON.stringify(this.audit));
                    if (this.fileList && this.fileList.length > 0) {
                        for (let i = 0; i < this.fileList.length; i++) {
                            if (!this.fileList[i].downloadUrl) {
                                param.append("files", this.fileList[i]);
                            }
                        }
                    }
                    this.$axios
                        .formPost("kybAudit/commitAudit", param)
                        .then(data => {
                            if (data.data === "success") {
                                alert("提交成功");
                                this.addAuditT.hide();
                                this.allAuditT.hide();
                                this.$refs.auditList.searchAudit();
                            } else if (data.data === "false") {
                                alert("文件上传失败");
                                this.addAuditT.hide();
                                this.allAuditT.hide();
                                this.$refs.auditList.searchAudit();
                            } else if (data.data === "result") {
                                alert("只有人力资源行政部员工才能提交工资核算");
                                this.addAuditT.hide();
                                this.allAuditT.hide();
                                this.$refs.auditList.searchAudit();
                            } else {
                                alert("系统错误");
                            }
                            //弹窗结束
                            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }
            }
        },
        addExpense: function() {
            const expense = { expenseDesc: "", expenseSum: "", expenseNum: "" };
            this.expenseList.push(expense);
        },
        reduceExpense: function(index) {
            if (this.expenseList && this.expenseList.length > 1) {
                this.expenseList.splice(index, 1);
            } else {
                alert("必须要有一个报销事项");
            }
        },
        countMoney: function(item) {
            if (isNaN(item.expenseSum) || item.expenseSum <= 0) {
                alert("报销金额必须是大于0的数字");
                this.$set(item, "expenseSum", null);
                this.cleanData();
                return;
            }

            let money = 0;
            for (let i = 0; i < this.expenseList.length; i++) {
                if (this.expenseList[i].expenseSum) {
                    money = (money * 100 + parseFloat(this.expenseList[i].expenseSum) * 100) / 100;
                    this.$set(this.audit, "money", money);
                }
            }
            this.cleanData();
        },
        draftAudit: function() {
            if (this.audit && this.audit.recordId && this.audit.oaDepartId) {
                if (this.user.employeeId) {
                    var commit = {};
                    commit.auditId = this.audit.recordId;
                    commit.opertionUserId = this.user.employeeId;
                    // 弹窗开始
                    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                    this.$axios
                        .fetchPost("kybAudit/draftAudit", commit)
                        .then(data => {
                            if (data) {
                                alert(data.data);
                                this.addAuditT.hide();
                                this.allAuditT.hide();
                                this.$refs.auditList.searchAudit();
                            }
                            // // 弹窗结束
                            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                        })
                        .catch(err => {
                            console.log(err);
                        });
                } else {
                    alert("登录失效，请登录");
                }
            } else {
                alert("审批单或者提交人部门数据不全，请刷新重试");
            }
        },
        assent: function() {
            if (this.content) {
                if (this.audit && this.audit.recordId && this.audit.oaDepartId) {
                    if (this.user.employeeId) {
                        var commit = {};
                        commit.auditId = this.audit.recordId;
                        commit.opertionUserId = this.user.employeeId;
                        commit.commitPersonAllId = this.audit.oaDepartId;
                        commit.content = this.content;
                        commit.applyCompanyId = this.audit.applyCompanyId;
                        commit.repairCompanyId = this.audit.repairCompanyId;
                        commit.statusFlag = this.audit.statusFlag;
                        // 弹窗开始
                        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                        this.$axios
                            .fetchPost("kybAudit/approveAssent", commit)
                            .then(data => {
                                if (data) {
                                    if (data.data === 0) {
                                        alert("审批成功！");
                                    } else if (data.data === 2) {
                                        alert("审批成功！已自动跳过后续审批人相同环节！");
                                    } else if (data.data === "operationFail") {
                                        alert("操作无效，单据已有变动！");
                                    } else {
                                        alert("审批失败！");
                                    }
                                    this.addAuditT.hide();
                                    this.allAuditT.hide();
                                    this.assentT.hide();
                                    this.$refs.auditList.searchAudit();
                                }
                                // // 弹窗结束
                                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                            })
                            .catch(err => {
                                console.log(err);
                            });
                    } else {
                        alert("登录失效，请登录");
                    }
                } else {
                    alert("审批单或者提交人部门数据不全，请刷新重试");
                }
            } else {
                alert("请填写理由");
            }
        },
        reject: function() {
            if (this.content) {
                if (
                    this.auditRecord &&
                    this.auditRecord.approveLevel &&
                    this.auditRecord.recordId
                ) {
                    if (this.audit && this.audit.recordId && this.audit.oaDepartId) {
                        if (this.user.employeeId) {
                            var commit = {};
                            commit.rejectUserId = this.auditRecord.recordId;
                            commit.rejectApproveLevel = this.auditRecord.approveLevel;
                            commit.auditId = this.audit.recordId;
                            commit.opertionUserId = this.user.employeeId;
                            commit.commitPersonAllId = this.audit.oaDepartId;
                            commit.content = this.content;
                            // 弹窗开始
                            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                            this.$axios
                                .fetchPost("kybAudit/approveReject", commit)
                                .then(data => {
                                    if (data) {
                                        if (data.data === 0) {
                                            alert("驳回成功！");
                                        } else if (data.data === 2) {
                                            alert("驳回成功！已自动跳过后续审批人相同环节！");
                                        } else if (data.data === "operationFail") {
                                            alert("操作无效，单据已有变动！");
                                        } else {
                                            alert("驳回失败！");
                                        }
                                        this.addAuditT.hide();
                                        this.allAuditT.hide();
                                        this.rejectT.hide();
                                        this.$refs.auditList.searchAudit();
                                    }
                                    // 弹窗结束
                                    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                                })
                                .catch(err => {
                                    console.log(err);
                                });
                        } else {
                            alert("登录失效，请登录");
                        }
                    } else {
                        alert("审批单或者提交人部门数据不全，请刷新重试");
                    }
                } else {
                    alert("请选择驳回节点");
                }
            } else {
                alert("请填写理由");
            }
        },
        assentContent: function() {
            this.content = "通过";
            this.assentT.show();
        },
        rejectContent: function() {
            this.content = "驳回";
            this.rejectT.show();
        },
        getDept: function() {
            for (let i in this.companyList) {
                if (this.audit.stampCompany === this.companyList[i].recordId) {
                    this.departList = this.companyList[i].departList;
                }
            }
        },
        getPayMsg: function() {
            if (this.audit && this.audit.payObject && this.user.employeeId) {
                const item = {};
                item.empId = this.user.employeeId;
                item.payObject = this.audit.payObject;
                //弹窗开始
                this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                this.$axios
                    .fetchPost("kybAudit/getPayMsg", item)
                    .then(data => {
                        if (data && data.data) {
                            if (data.data && data.data.bank) {
                                this.$set(this.audit, "bank", data.data.bank);
                            }
                            if (data.data && data.data.bankAccount) {
                                this.$set(this.audit, "bankAccount", data.data.bankAccount);
                            }
                        }
                        //弹窗结束
                        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        },
        // 金额和数量改变时，分摊的数据清空
        cleanData: function() {
            if (this.auditGroupDetailList && this.auditGroupDetailList.length > 0) {
                for (let item of this.auditGroupDetailList) {
                    this.$set(item, "quantity", null);
                    this.$set(item, "amount", null);
                    this.$set(item, "percentage", null);
                }
            }
        },
        // 印章复选框选中
        loadChildType: function() {
            var objList = document.getElementsByName("seal");
            if (objList && objList.length > 0) {
                if (this.audit.childTypeStr) {
                    var childTypeStrList = this.audit.childTypeStr.split(",");
                    if (objList) {
                        for (let obj of objList) {
                            for (let childTypeStr of childTypeStrList) {
                                if (obj.value === childTypeStr) {
                                    this.$set(obj, "checked", true);
                                    break;
                                }
                            }
                        }
                    }
                }
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                // 递归 等待dom渲染完毕
                const _this = this;
                setTimeout(function() {
                    _this.loadChildType();
                }, 500);
            }
        },
        // 报销申请单提交
        reimbursementSubmit: function() {
            if (!this.audit.reimbursementFor || this.audit.reimbursementFor === "") {
                alert("请填写评论内容！");
                return;
            }
            // 弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            // 通过form表单进行提交
            const param = new FormData();
            param.append("audit", JSON.stringify(this.audit));
            if (this.fileList && this.fileList.length > 0) {
                for (let i = 0; i < this.fileList.length; i++) {
                    if (!this.fileList[i].downloadUrl) {
                        param.append("files", this.fileList[i]);
                    }
                }
            }
            this.$axios
                .formPost("kybAudit/commitAudit", param)
                .then(data => {
                    if (data.data === "success") {
                        alert("提交成功");
                        this.addAuditT.hide();
                        this.allAuditT.hide();
                        this.reimbursementWindowT.hide();
                        this.$refs.auditList.searchAudit();
                    } else if (data.data === "false") {
                        alert("文件上传失败");
                        this.addAuditT.hide();
                        this.allAuditT.hide();
                        this.reimbursementWindowT.hide();
                        this.$refs.auditList.searchAudit();
                    } else {
                        alert("系统错误");
                    }
                    //弹窗结束
                    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                })
                .catch(err => {
                    console.log(err);
                });
        },
        initSelectFour: function(id, typeId, list, bindId, bindValue) {
            $("#" + id).empty();
            let option = "";
            option += "<option value=''>" + "" + "</option>";
            list.forEach(el => {
                option +=
                    "<option value='" +
                    eval("el." + bindId) +
                    "'>" +
                    eval("el." + bindValue) +
                    "</option>";
            });
            $("#" + id).append(option);
            $("#" + id).selectpicker("val", typeId);
            $("#" + id).selectpicker("render");
            $("#" + id).selectpicker("refresh");
            $("#" + id).selectpicker();
        },
        getCustomerList: function(num) {
            let customerList = [];
            for (let cus of this.customerList) {
                if (cus.companyId === this.erpCompanyId) {
                    customerList.push(cus);
                }
            }
            if (num) {
                this.customerId = null;
            }
            if ($("#customer").is(":visible")) {
                this.initSelectFour(
                    "customer",
                    this.customerId,
                    customerList,
                    "recordId",
                    "concatValue"
                );
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                const _this = this;
                // 递归 等待dom渲染完毕
                setTimeout(function() {
                    _this.initSelectFour(
                        "customer",
                        _this.customerId,
                        customerList,
                        "recordId",
                        "concatValue"
                    );
                }, 500);
            }
        },
        delAudit(item) {
            this.audit = item;
            this.delAuditT.show();
        },
        deleteAudit() {
            // 弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            this.$axios
                .fetchPost("kybAudit/deleteAudit", this.audit)
                .then(data => {
                    if (data.data) {
                        if (data.data === "success") {
                            this.audit = {};
                            alert("删除成功");
                            this.$refs.auditList.searchAudit();
                            this.delAuditT.hide();
                            // // 弹窗结束
                            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                        } else {
                            alert("系统错误");
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        saveReimbursement: function () {
            if (this.audit) {
                if (this.companyId) {
                    this.audit.oaDepartId = this.companyId;
                    this.audit.empId = this.user.employeeId;
                    this.audit.groupId = this.user.departId;
                } else {
                    alert('请选择架构');
                    return;
                }
                let totalAmount = 0
                let qty = 0
                if (this.expenseList && this.expenseList.length > 0) {
                    for (let i = 0; i < this.expenseList.length; i++) {
                        if (this.expenseList[i].expenseDesc && this.expenseList[i].expenseSum && this.expenseList[i].expenseNum) {
                            continue
                        } else {
                            alert('报销明细的内容需要填写完整！')
                            return
                        }
                    }
                } else {
                    alert('请填报销明细！')
                    return
                }
                if (!this.audit.auditResult) {
                    alert('请填写原因')
                    return
                }
                this.audit.expenseList = this.expenseList
                totalAmount = this.audit.money
                if (this.auditGroupDetailList && this.auditGroupDetailList.length > 0) {
                    var total = 0
                    var list = [] // 保存的分摊记录(金额大于0的)
                    for (let obj of this.auditGroupDetailList) {
                        list.push(obj)
                        if (this.audit.auditType === '22002') {
                            if (obj.quantity && Number(obj.quantity) > 0) {
                                total += Number(obj.quantity)
                            }
                        } else {
                            if (obj.amount && Number(obj.amount) > 0) {
                                total += Number(obj.amount)
                            }
                        }
                    }
                    if (this.audit.auditType !== '22002') {
                        total = Number(total.toFixed(4))
                    }
                    if (total === 0) {
                        // 不分摊
                    } else if (this.audit.auditType === '22002' && total != Number(qty)) {
                        alert('所有分摊的数量要等于总数量')
                        return
                    } else if (this.audit.auditType !== '22002' && total != Number(totalAmount)) {
                        alert('所有分摊的金额要等于总金额')
                        return
                    } else {
                        this.audit.auditGroupDetailList = list
                    }
                }
                this.audit.applicationsType = '1'
                this.audit.expenseTypeId = this.expenseTypeId
                //弹窗开始
                this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                // 通过form表单进行提交
                const param = new FormData()
                param.append('audit', JSON.stringify(this.audit))
                if (this.fileList && this.fileList.length > 0) {
                    for (let i = 0; i < this.fileList.length; i++) {
                        if (!this.fileList[i].downloadUrl) {
                            param.append('files', this.fileList[i])
                        }
                    }
                }
                this.$axios.formPost('kybAudit/commitAudit', param).then((data) => {
                    if (data.data === 'success') {
                        alert('保存成功')
                        $('#loadingModal').modal('hide')
                        /*            $('#addAudit').modal('hide')
                        $('#allAudit').modal('hide')
                        this.searchAudit() */
                    } else if (data.data === 'false') {
                        alert('文件上传失败')
                        $('#addAudit').modal('hide')
                        $('#allAudit').modal('hide')
                        this.searchAudit()
                    } else {
                        alert('系统错误')
                    }
                    //弹窗结束
                    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                }).catch(err => {
                        console.log(err)
                    }
                )
            }
        },
        //加载销售客户列表
        getSaleCustomerList : function ()
        {
            // 弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            const query = {};
            query.companyId = this.saleCompanyId;
            this.$axios
                .fetchPost("kybOa/getSaleCustomerList", query)
                .then(data => {
                    if (data.data)
                    {
                        this.saleCustomerList = data.data;
                        this.handSaleCustomerList();
                        // 弹窗结束
                        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                        } else {
                            alert("系统错误");
                        }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        handSaleCompanyList: function() {
            $('#erpSaleCompanyId').empty();
            let option = "";
            option += "<option value=''>" + "请选择" + "</option>";
            this.saleCompanyList.forEach(el => {
                option +=
                    "<option value='" +
                    el.recordId +
                    "'>" +
                    el.name +
                    "</option>";
            });
            $("#erpSaleCompanyId").append(option);
            $("#erpSaleCompanyId").selectpicker("val", this.saleCompanyId);
            $("#erpSaleCompanyId").selectpicker("render");
            $("#erpSaleCompanyId").selectpicker("refresh");
            $("#erpSaleCompanyId").selectpicker();
            if (!this.saleCompanyId && this.saleCompanyList[0]) {
                this.saleCompanyId = this.saleCompanyList[0].recordId;
            }
            $("#erpSaleCompanyId").selectpicker("val", this.saleCompanyId);
        },
        handSaleCustomerList: function ()
        {
            $('#erpsaleCustomId').empty();
            let option = "";
            option += "<option value=''>" + "请选择" + "</option>";
            this.saleCustomerList.forEach(el => {
                option +=
                    "<option value='" +
                    el.recordId +
                    "'>" +
                    el.name +
                    "</option>";
            });
            $("#erpsaleCustomId").append(option);
            $("#erpsaleCustomId").selectpicker("val", this.saleCustomId);
            $("#erpsaleCustomId").selectpicker("render");
            $("#erpsaleCustomId").selectpicker("refresh");
            $("#erpsaleCustomId").selectpicker();
        },
    }
};
