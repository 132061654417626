<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom card-stretch">
          <div class="card-body d-flex flex-column p-0">
            <div class="flex-grow-1 card-spacer-x">
              <div class="row pt-2 pb-1 border-bottom">
                <div class="col-xl-12">
                  <h5 class="font-weight-bolder text-dark">审批管理</h5>
                </div>
              </div>
              <div class="row pt-2 pb-2">
                <div class="col-xl-2">
                  审批类型
                  <select
                    class="form-control form-control-sm"
                    v-model="search.auditType"
                    v-on:change="searchAudit"
                  >
                    <option value="">全部</option>
                    <template v-for="item in allAuditList">
                      <option :key="item.recordId" :value="item.type">
                        {{ item.name }}
                      </option>
                    </template>
                  </select>
                </div>
                <div class="col-xl-2">
                  单据状态
                  <select
                    class="form-control form-control-sm"
                    v-model="search.applicationsResult"
                    v-on:change="searchAudit"
                  >
                    <option value="all">所有</option>
                    <option value="">正常</option>
                    <option value="assent">通过</option>
                    <option value="reject">驳回</option>
                  </select>
                </div>
                <div class="col-xl-2">
                  查询方式
                  <select
                    type="text"
                    class="form-control form-control-sm"
                    v-model="search.timeType"
                    v-on:change="searchAudit"
                  >
                    <option value="">提交时间</option>
                    <option value="1">完成时间</option>
                  </select>
                </div>
                <div class="col-xl-2">
                  流程状态
                  <select
                    class="form-control form-control-sm"
                    v-model="search.procedureStatus"
                    v-on:change="searchAudit"
                  >
                    <option value="">所有</option>
                    <option value="1">完成</option>
                    <option value="2">审批中</option>
                  </select>
                </div>
                <div class="col-xl-2">
                  开始时间
                  <input id="startTime" class="form-control form-control-sm" />
                </div>
                <div class="col-xl-2">
                  结束时间
                  <input id="endTime" class="form-control form-control-sm" />
                </div>
                <div class="col-xl-2">
                  费用类型
                  <select
                    class="form-control form-control-sm"
                    v-model="expenseTypeTwo"
                    v-on:change="searchAudit"
                  >
                    <option value="">所有</option>
                    <option
                      v-for="item in expenseTypeList"
                      :key="item.recordId"
                      :value="item.recordId"
                    >
                      {{ item.no }}{{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="col-xl-2">
                  提交人
                  <select
                    v-model="summitUserId"
                    class="form-control selectpicker"
                    data-max-options="1"
                    v-on:change="searchAudit"
                    data-none-selected-text="不选择"
                    data-live-search="true"
                    id="summitUser"
                    data-size="6"
                  >
                  </select>
                </div>
                <div class="col-xl-2">
                  交接人员
                  <select
                    v-model="handOverId"
                    class="form-control selectpicker"
                    data-max-options="1"
                    v-on:change="searchAudit"
                    data-none-selected-text="不选择"
                    data-live-search="true"
                    id="handOver"
                    data-size="6"
                  >
                  </select>
                </div>
                <div class="col-xl-6">
                  审批内容
                  <input
                    class="form-control form-control-sm"
                    v-model="search.condition"
                    v-on:change="searchAudit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12 pt-2 pb-2">
        <div class="card card-custom card-stretch">
          <div class="card-body d-flex flex-column p-0">
            <div class="flex-grow-1 card-spacer-x">
              <div class="row pt-1 pb-1 align-items-center">
                <div class="col-xl-3">清单列表</div>
                <div class="col-xl-9 text-right">
                  <button class="btn-sm btn btn-primary" v-on:click="allAudit">
                    提交审批</button
                  >&nbsp;
                  <span v-if="auditList && auditList.length > 0">
                    <form
                      :action="exportUrl"
                      method="post"
                      enctype="multipart/form-data"
                      style="display:inline;"
                    >
                      <input
                        type="text"
                        v-show="false"
                        name="defaultDb"
                        :value="user.defaultDb"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="flag"
                        :value="search.flag"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="empId"
                        :value="search.empId"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="auditType"
                        :value="search.auditType"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="condition"
                        :value="search.condition"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="applicationsResult"
                        :value="search.applicationsResult"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="startTime"
                        :value="search.startTime"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="endTime"
                        :value="search.endTime"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="procedureStatus"
                        :value="search.procedureStatus"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="expenseType"
                        :value="search.expenseType"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="summitUserId"
                        :value="search.summitUserId"
                      />
                      <button type="submit" class="btn-sm btn btn-secondary">
                        导出EXCEL
                      </button>
                    </form> </span
                  >&nbsp;
                  <button
                    class="btn-sm btn btn-secondary"
                    v-on:click="batchPrint"
                  >
                    批量打印</button
                  >&nbsp;
                  <span v-if="auditRight && auditRight.level == 3">
                    <form
                      :action="exportAllUrl"
                      method="post"
                      enctype="multipart/form-data"
                      style="display:inline;"
                    >
                      <input
                        type="text"
                        v-show="false"
                        name="defaultDb"
                        :value="user.defaultDb"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="startTime"
                        :value="search.startTime"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="endTime"
                        :value="search.endTime"
                      />
                      <button type="submit" class="btn-sm btn btn-secondary">
                        导出所有报销单
                      </button>
                    </form> </span
                  >&nbsp;
                  <span v-if="auditRight && auditRight.level == 3">
                    <form
                      :action="exportAccountUrl"
                      method="post"
                      enctype="multipart/form-data"
                      style="display:inline;"
                    >
                      <input
                        type="text"
                        v-show="false"
                        name="defaultDb"
                        :value="user.defaultDb"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="flag"
                        :value="search.flag"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="empId"
                        :value="search.empId"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="auditType"
                        :value="search.auditType"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="condition"
                        :value="search.condition"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="applicationsResult"
                        :value="search.applicationsResult"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="startTime"
                        :value="search.startTime"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="endTime"
                        :value="search.endTime"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="procedureStatus"
                        :value="search.procedureStatus"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="expenseType"
                        :value="search.expenseType"
                      />
                      <input
                        type="text"
                        v-show="false"
                        name="summitUserId"
                        :value="search.summitUserId"
                      />
                      <button type="submit" class="btn-sm btn btn-secondary">
                        会计审批单
                      </button>
                    </form>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="card card-custom gutter-b card-stretch">
          <div class="card-body d-flex flex-column p-0">
            <div class="flex-grow-1 card-spacer-x">
              <div class="row">
                <div class="col-xl-2">
                  <ul class="nav flex-column nav-pills pt-3">
                    <li class="nav-item">
                      <a
                        :class="auditFlag == 1 ? 'nav-link active' : 'nav-link'"
                        href="#"
                        v-on:click="searchAudit(1)"
                        style="cursor: pointer"
                        >待办审批</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        :class="auditFlag == 2 ? 'nav-link active' : 'nav-link'"
                        href="#"
                        v-on:click="searchAudit(2)"
                        style="cursor: pointer"
                        >已办审批</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        :class="auditFlag == 3 ? 'nav-link active' : 'nav-link'"
                        href="#"
                        v-on:click="searchAudit(3)"
                        style="cursor: pointer"
                        >我提交的审批</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        :class="auditFlag == 4 ? 'nav-link active' : 'nav-link'"
                        href="#"
                        v-on:click="searchAudit(4)"
                        style="cursor: pointer"
                        >抄送给我的审批</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                          :class="auditFlag == 6 ? 'nav-link active' : 'nav-link'"
                          href="#"
                          v-on:click="searchAudit(6)"
                          style="cursor: pointer"
                      >通知给我的审批</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        :class="auditFlag == 5 ? 'nav-link active' : 'nav-link'"
                        href="#"
                        v-on:click="searchAudit(5)"
                        style="cursor: pointer"
                        >全部审批</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="col-xl-10 border-left">
                  <div class="row align-items-center pt-3 pb-3 border-bottom">
                    <div class="col-xl-12">
                      <input
                        type="checkbox"
                        v-model="allCheck"
                        v-on:change="selectionAllCheck"
                      />&nbsp;全选
                    </div>
                  </div>
                  <template v-for="item in auditList">
                    <div
                      class="row align-items-center pt-3 pb-3 border-bottom"
                      :key="item.recordId"
                    >
                      <div class="col-xl-12">
                        <div class="row align-items-center">
                          <div class="col-xl-4">
                            <input
                              type="checkbox"
                              v-model="item.check"
                              v-on:change="setRecordIds"
                            />&nbsp;
                            <span class="font-weight-bolder"
                              >{{ item.auditTypeCh }}-{{ item.no }}</span
                            >
                          </div>
                          <div class="col-xl-6">
                            <span class="font-size-sm pr-5"
                              >[<span v-if="item.auditType !== '22026'">提交人：{{ item.name }}</span>{{ item.createdDate }}]</span
                            >
                            <span
                              v-if="
                                item.applicationsType === '2' &&
                                  item.positionName
                              "
                              >[待{{ item.positionName }}审批]</span
                            >
                            <span v-if="item.applicationsType === '1'"
                              >草稿</span
                            >
                            <span
                              v-if="
                                item.applicationsType === '3' &&
                                  item.applicationsResult === 'reject'
                              "
                              >已驳回</span
                            >
                            <span
                              v-if="
                                item.applicationsType === '3' &&
                                  item.applicationsResult === 'assent'
                              "
                              >{{ item.lastUpdDate }}已通过</span
                            >
                          </div>
                          <div class="col-xl-2 text-right">
                            <button
                              class="btn-sm btn btn-primary px-1 py-1"
                              v-on:click="viewAudit(item)"
                            >
                              打开</button
                            >&nbsp;
                            <button
                              class="btn-sm btn btn-success px-1 py-1"
                              v-on:click="auditPrint(item)"
                            >
                              打印</button
                            >&nbsp;
                            <button
                              class="btn-sm btn btn-danger px-1 py-1"
                              v-on:click="delAudit(item)"
                              v-if="
                                  (item.empId === user.employeeId &&
                                  item.applicationsType === '3' &&
                                  item.applicationsResult === 'reject')
                                  || item.applicationsType === '1'
                              "
                            >
                              删除
                            </button>
                          </div>
                        </div>
                        <div class="row pt-2 text-muted">
                          <div class="col-xl-9">
                            <div v-html="item.auditResult" v-if="item.auditResult"></div>
                            <div v-html="item.remark" v-if="item.remark"></div>
                          </div>
                          <div class="col-xl-3" v-if="item.expensePrice">
                            金额:{{item.expensePrice}}
                          </div>
                          <div class="col-xl-3" v-if="item.amountReceived">
                            汇款金额:{{item.amountReceived}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="delAudit" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row pt-3">
              <div class="col-sm-12">
                确定删除（{{audit.auditTypeCh}}-{{audit.no}}（{{audit.auditResult}}））这个审批单吗？
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
            <button type="button" class="btn btn-danger" v-on:click="deleteAudit">删除</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";
import {Modal} from "bootstrap";
export default {
  computed: {
    auditList: {
      get() {
        return this.$store.state.auditStore.auditList;
      }
    },
    expenseTypeList: {
      get() {
        return this.$store.state.auditStore.expenseTypeList;
      }
    },
    handEmpList: {
      get() {
        return this.$store.state.auditStore.handEmpList;
      }
    }
  },
  props: {
    user: {},
    oaEmpList: Array,
    allAuditList: Array
  },
  watch: {
    user: function() {
      this.initData();
    },
    auditList: function() {
      this.allCheck = false;
      // 弹窗结束;
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },
    handEmpList: function() {
      let _this = this;
      if ($("#handOver").is(":visible")) {
        this.initSelectThree("handOver", this.handOverId, _this.handEmpList);
      } else {
        if (this.temp > 50) {
          this.temp = 0;
        }
        this.temp++;
        // 递归 等待dom渲染完毕
        _this = this;
        setTimeout(function() {
          _this.initSelectThree(
            "handOver",
            _this.handOverId,
            _this.handEmpList
          );
        }, 500);
      }
    },
    oaEmpList: function() {
      const _this = this;
      if ($("#summitUser").is(":visible")) {
        this.initSelectThree("summitUser", this.summitUserId, _this.oaEmpList);
      } else {
        if (this.temp > 50) {
          this.temp = 0;
        }
        this.temp++;
        // 递归 等待dom渲染完毕
        setTimeout(function() {
          _this.initSelectThree(
            "summitUser",
            _this.summitUserId,
            _this.oaEmpList
          );
        }, 500);
      }
    }
  },
  mounted() {
    this.initData();
    this.delAuditT = new Modal(this.$refs.delAudit);
  },
  data() {
    return {
      companyId: '',
      audit:{},
      auditRight: {},
      delAuditT: "",
      temp: 0,
      handOverId: "",
      summitUserId: "",
      expenseTypeTwo: "",
      allCheck: false,
      search: {
        applicationsResult: "all",
        timeType: "",
        flag: "1",
        auditType: ""
      },
      auditFlag: 1,
      exportUrl: this.url + "/kybAudit/export",
      exportAllUrl: this.url + "/kybAudit/exportAll",
      exportAccountUrl: this.url + "/kybAudit/accountingApproval",
    };
  },
  methods: {
    initData: function() {
      this.auditRight = {};
      if (this.user && this.user.itemList) {
        for (let item of this.user.itemList) {
          if (item.nodeId === "101") {
            this.auditRight = item;
            break;
          }
        }
      }
      if (!this.recordId) {
        this.recordId = localStorage.getItem("recordId");
        window.localStorage.removeItem("recordId");
      }
      this.getDate();
      if (this.user && this.user.recordId) {
        this.$store.dispatch("auditStore/getAuditHandOver", this.user);
        const depart = {};
        depart.recordId = this.user.departId;
        depart.workStatus = 1;
        this.$store.dispatch("systemData/loadOaEmpList", depart);
        this.$store.dispatch("auditStore/getExpenseTypeList");
        this.$store.dispatch("systemData/getAllPositionList", this.user);
        // 弹窗开始;
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        const audit = {};
        if (this.recordId) {
          audit.recordId = this.recordId;
        } else {
          audit.flag = "1";
          if (this.handOverId) {
            audit.empId = this.handOverId;
          } else {
            audit.empId = this.user.employeeId;
          }
          audit.startTime = $("#startTime").val();
          audit.endTime = $("#endTime").val();
        }
        this.$store.dispatch("auditStore/getAuditList", audit);
      }
    },
    searchAudit: function(num) {
      if (num && Number(num) > 0) {
        this.auditFlag = num;
      } else {
        num = this.auditFlag;
      }
      // 弹窗开始;
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      const audit = {};
      if (num && num > 0) {
        audit.flag = num;
      } else {
        if (this.search.flag) {
          audit.flag = this.search.flag;
        } else {
          audit.flag = "1";
        }
      }
      this.search.flag = audit.flag;
      this.auditFlag = audit.flag;
      if (this.handOverId) {
        audit.empId = this.handOverId;
      } else {
        audit.empId = this.user.employeeId;
      }
      this.search.empId = audit.empId;
      audit.auditType = this.search.auditType;
      audit.condition = this.search.condition;
      audit.applicationsResult = this.search.applicationsResult;
      audit.timeType = this.search.timeType;

      audit.procedureStatus = this.search.procedureStatus;
      audit.expenseType = this.expenseTypeTwo;
      this.search.expenseType = this.expenseTypeTwo;
      audit.summitUserId = this.summitUserId;
      this.search.summitUserId = this.summitUserId;

      if (this.search.startTime) {
        audit.startTime = this.search.startTime;
      } else {
        audit.startTime = $("#startTime").val();
      }
      this.search.startTime = audit.startTime;
      if (this.search.endTime) {
        audit.endTime = this.search.endTime;
      } else {
        audit.endTime = $("#endTime").val();
      }
      this.search.endTime = audit.endTime;
      this.$store.dispatch("auditStore/getAuditList", audit);
    },
    allAudit: function() {
      this.$emit("allAudit");
    },
    delAudit: function(item) {
      this.audit = item;
      this.delAuditT.show();
    },
    deleteAudit() {
      // 弹窗开始
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$axios
          .fetchPost("kybAudit/deleteAudit", this.audit)
          .then(data => {
            if (data.data) {
              if (data.data === "success") {
                this.audit = {};
                alert("删除成功");
                this.searchAudit();
                this.delAuditT.hide();
                // // 弹窗结束
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
              } else {
                alert("系统错误");
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
    },
    batchPrint: function() {
      if (this.recordIds) {
        let recordIds = this.recordIds.split(",");
        for (let id of recordIds) {
          let item = {};
          item.recordId = id;
          this.auditPrint(item);
        }
      }
    },
    initSelectThree: function(id, typeId, list) {
      $("#" + id).empty();
      let option = "";
      option += "<option value=''>" + "所有" + "</option>";
      list.forEach(el => {
        option +=
          "<option value='" + el.recordId + "'>" + el.name + "</option>";
      });
      $("#" + id).append(option);
      $("#" + id).selectpicker("val", typeId);
      $("#" + id).selectpicker("render");
      $("#" + id).selectpicker("refresh");
      $("#" + id).selectpicker();
      if (!typeId && this.expenseTypeList[0]) {
        typeId = this.expenseTypeList[0].recordId;
      }
      $("#" + id).selectpicker("val", typeId);
    },
    // 获取时间
    getDate: function() {
      let startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 2);
      let startYear = startDate.getFullYear();
      let startMonth = startDate.getMonth() + 1;
      if (startMonth.length > 1) {
        startMonth = "0" + startDate.getMonth() + 1;
      } else {
        startMonth = startDate.getMonth() + 1;
      }
      let startDay =
        startDate.getDate() >= 10
          ? startDate.getDate()
          : "0" + startDate.getDate();
      let startTime = startYear + "-" + startMonth + "-" + startDay;

      let endDate = new Date();
      let endYear = endDate.getFullYear();
      let endMonth = endDate.getMonth() + 1;
      endMonth = endMonth < 10 ? "0" + endMonth : endMonth;
      let endDay =
        endDate.getDate() >= 10 ? endDate.getDate() : "0" + endDate.getDate();
      let endTime = endYear + "-" + endMonth + "-" + endDay;

      this.search.startTime = startTime;
      this.search.endTime = endTime;
      this.initQueryDate("startTime", startTime);
      this.initQueryDate("endTime", endTime);
    },
    selectionAllCheck: function() {
      for (let obj of this.auditList) {
        obj.check = this.allCheck;
      }
      this.setRecordIds();
    },
    setRecordIds: function() {
      let recordIds = null;
      for (let obj of this.auditList) {
        if (obj.check) {
          if (recordIds) {
            recordIds = recordIds + "," + obj.recordId;
          } else {
            recordIds = obj.recordId;
          }
        }
      }
      this.recordIds = recordIds;
    },
    viewAudit: function(item, num) {
      // 弹窗开始
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.passUserId = [];
      this.companyId = "";
      item.auditUserId = this.user.employeeId;
      this.num = "";
      this.num = num;
      this.$axios
        .fetchPost("kybAudit/getAudit", item)
        .then(data => {
          if (data && data.data) {
            if (data.data.oaDepartId) {
              this.companyId = data.data.oaDepartId;
            }
            this.$emit("openAudit", data.data, this.companyId);
          }
          // // 弹窗结束
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        })
        .catch(err => {
          console.log(err);
        });
    },
    auditPrint: function(item) {
      item.defaultDb = this.user.defaultDb;
      if (item.auditType === "22003") {
        this.auditPrintBx(item);
        return;
      }
      this.$axios
        .fetchPostArray("kybAudit/print", item)
        .then(data => {
          const headers = data.headers;
          if (data.data && data.data.byteLength === 0) {
            alert("打印失败");
          } else {
            const a = document.createElement("a");
            const file = new Blob([data.data], {
              type: "application/pdf"
            });
            const fileURL = (window.URL || window.webkitURL).createObjectURL(
              file
            );
            a.href = fileURL;
            a.download = decodeURI(headers.title);
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
              document.body.removeChild(a);
              URL.revokeObjectURL(fileURL);
            }, 100);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    auditPrintBx: function(item) {
      this.$axios
        .fetchPostArray("kybAudit/printBx", item)
        .then(data => {
          const headers = data.headers;
          if (data.data && data.data.byteLength === 0) {
            alert("打印失败");
          } else {
            const a = document.createElement("a");
            const file = new Blob([data.data], {
              type: "application/pdf"
            });
            const fileURL = (window.URL || window.webkitURL).createObjectURL(
              file
            );
            a.href = fileURL;
            a.download = decodeURI(headers.title);
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
              document.body.removeChild(a);
              URL.revokeObjectURL(fileURL);
            }, 100);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    initQueryDate: function(id, startDates) {
      if ($("#" + id + "").is(":visible")) {
        const _this = this;
        $("#" + id + "").daterangepicker(
          {
            singleDatePicker: true,
            showDropdowns: true,
            timePicker: true,
            timePicker24Hour: false,
            startDate: startDates, // 设置开始日期
            opens: "center",
            drops: "down",
            locale: {
              format: "YYYY-MM-DD",
              separator: " - ",
              applyLabel: "确定",
              cancelLabel: "取消",
              fromLabel: "From",
              toLabel: "到",
              customRangeLabel: "Custom",
              weekLabel: "W",
              daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
              monthNames: [
                "一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月"
              ],
              firstDay: 1
            }
          },
          function(start) {
            if (id === "startTime") {
              _this.search.startTime = start.format("YYYY-MM-DD");
            } else if (id === "endTime") {
              _this.search.endTime = start.format("YYYY-MM-DD");
            }
            _this.searchAudit();
          }
        );
      } else {
        if (this.temp > 50) {
          this.temp = 0;
        }
        this.temp++;
        // 递归 等待dom渲染完毕
        const _this = this;
        setTimeout(function() {
          _this.initQueryDate(id, startDates);
        }, 500);
      }
    }
  }
};
</script>
